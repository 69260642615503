import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store'

import pdf from 'vue-pdf'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import i18n from '@/modules/i18n'
import * as Sentry from '@sentry/vue'
import { msalInstance } from '@/modules/msal'
import vueModal from '@/modules/vueModal'
import browser from '@/modules/browser'
import dialog from '@/modules/dialog'
import scrollLock from '@/modules/scrollLock'
import vueLogger from '@/modules/vueLogger'
const { name, version } = require('../package.json')

import bienvenue_components from '../node_modules/@bsoft/bienvenue_components/'

import { initWhiteLabel } from '@/modules/whiteLabel.js'

initWhiteLabel()

//Bienvenu Components
Object.values(bienvenue_components).forEach(component => {
  Vue.component(component.name, component)
})

Vue.component('pdf', pdf)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

//Global variables, based on .env file
//need to be rework (delete all variables and replace by process.env)
Vue.prototype.$isPartner = function() {
  return process.env.VUE_APP_PARTNER === 'true'
}

Vue.prototype.$allowSsoMicrosoft = function() {
  return process.env.VUE_APP_SSO_MICROSOFT_ALLOW === 'true'
}

Vue.prototype.$allowSSoGoogle = function() {
  return process.env.VUE_APP_GOOGLE_CLIENT_ID
}

Vue.prototype.$allowCrisp = function() {
  return process.env.VUE_APP_CRISP_ALLOW === 'true'
}

Vue.prototype.$appName = function() {
  return process.env.VUE_APP_NAME
}

Vue.prototype.$msalInstance = function() {
  return msalInstance
}

Vue.config.productionTip = false

//Crisp conf

if (process.env.VUE_APP_CRISP_ALLOW !== 'false') {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = '33dbd12e-3614-42a1-92e8-43ee4f490a78'
  ;(function() {
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()

  // Désactiver l'avertissement concernant les shims
  $crisp.push(['safe', true])
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  release: `${name + ':' + version}`,

  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [process.env.VUE_APP_SENTRY_URL_TARGET],

  // Don't Capture Replay for standard sessions,
  replaysSessionSampleRate: 0.0,
  // (0.5%) for error
  replaysOnErrorSampleRate: 0.005,
  // Maximum 30 secondes per replay
  maxReplayDuration: 30
})

// Ajouter le gestionnaire global pour les promesses non gérées
window.addEventListener('unhandledrejection', function(event) {
  Sentry.captureException(event.reason)
})

// Optionnel : Ajouter également un gestionnaire pour les erreurs globales
window.addEventListener('error', function(event) {
  Sentry.captureException(event.error || event.message)
})

new Vue({
  store,
  i18n,
  vueModal,
  browser,
  dialog,
  scrollLock,
  vueLogger,
  render: h => h(App),
  router
}).$mount('#app')
