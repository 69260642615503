export default {
  temporaryVersion: {
    message: 'Pour accéder à la version beta de la nouvelle application',
    button: 'Accéder à la version beta'
  },
  utils: {
    label: {
      trainer: 'Animateur',
      firstName: 'Prénom',
      lastName: 'Nom',
      organization: 'Organisation : ',
      phone: 'Téléphone',
      establishment: 'Société',
      address: 'Adresse',
      city: 'Ville',
      country: 'Pays',
      zipcode: 'Code postal',
      email: 'Email',
      password: 'Mot de passe',
      confirmPassword: 'Confirmation du mot de passe',
      caution: 'Attention',
      yes: 'Oui',
      no: 'Non',
      signHere: 'Signer ici',
      required: 'requis',
      optional: 'optionel',
      error: 'Erreur',
      unavailable: 'Indisponible',
      help: 'Aide',
      loading: 'Chargement',
      seeMore: 'Voir plus',
      seeLess: 'Voir moins',
      survey: 'Questionnaire',
      training: 'Formation',
      section: 'Partie'
    },
    labelInfo: {
      survey: 'Questionnaire : ',
      training: 'Formation : ',
      sessionCode: 'Code Session: ',
      section: 'Partie : ',
      sectionDate: 'Date de la Partie : '
    },
    language: {
      title: 'Langue',
      french: 'Français',
      english: 'English'
    },
    action: {
      actions: 'Actions',
      cancel: 'Annuler',
      edit: 'Modifier',
      success: 'Succès',
      confirm: 'Confirmer',
      ok: 'Ok',
      close: 'Fermer',
      validate: 'Valider',
      present: 'Présent',
      next: 'Suivant',
      restart: 'Recommencer',
      replace: 'Remplacer',
      add: 'Ajouter',
      send: 'Envoyer',
      download: 'Télécharger',
      selectAll: 'Sélectionner tout',
      deselectAll: 'Désélectionner tout',
      back: 'Retour',
      backSafe: 'Retour en lieu sûr',
      new: 'Nouveau',
      info: 'Vous devez sélectionner au moins'
    },
    error: {
      title: 'Erreur',
      basic: 'Une erreur est survenue',
      notAvailable:
        'La date actuelle ne vous permet pas de réaliser cette action',
      minSearchString: 'Entrez 3 caractères ou plus',
      emptyResult: 'Aucun résultat',
      unknown: 'Erreur inconnue',
      evaluationCompleted: "L'évaluation a déjà été complétée",
      notFoundTitle: 'Page Non Trouvé',
      notFound:
        "Nous sommes désolés, la page que vous avez demandé n'existe pas ou n'existe plus.",
      temporalSigningNotAllowed:
        "Le mode d'émargement n'autorise pas le QR Code dynamique",
      sectionNotOngoing: 'La partie doit être en cours'
    },
    status: {
      present: 'Présent',
      presentNoSignature: 'Non signé',
      awaited: 'Attendu',
      absent: 'Absent',
      replaced: 'Remplacé'
    },
    statusTraining: {
      ongoing: 'En cours',
      canceled: 'Annuler',
      scheduled: 'A venir',
      closed: 'Clôturé'
    },
    months: {
      january: 'Janvier',
      february: 'Février',
      march: 'Mars',
      april: 'Avril',
      may: 'Mai',
      june: 'Juin',
      july: 'Juillet',
      august: 'Août',
      september: 'Septembre',
      october: 'Octobre',
      november: 'Novembre',
      december: 'Décembre'
    },
    monthsShort: {
      january: 'Jan',
      february: 'Fév',
      march: 'Mars',
      april: 'Avr',
      may: 'Mai',
      june: 'Juin',
      july: 'Juil',
      august: 'Août',
      september: 'Sept',
      october: 'Oct',
      november: 'Nov',
      december: 'Déc'
    },
    days: {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche'
    },
    daysShort: {
      monday: 'Lun',
      tuesday: 'Mar',
      wednesday: 'Mer',
      thursday: 'Jeu',
      friday: 'Ven',
      saturday: 'Sam',
      sunday: 'Dim'
    }
  },
  commonError: {
    title: 'Erreur',
    notFound: 'Elément non trouvé',
    forbidden: 'Non autorisé, action impossible',
    badRequest: 'Action non valide',
    unknown: 'Erreur inconnue'
  },
  routes: {
    title: {
      home: 'Accueil',
      account: 'Profil',
      calendar: 'Calendrier',
      login: 'Login',
      microsoftConnect: 'Microsoft Login',
      newSection: "Ajout d'une partie",
      survey: 'Questionnaire',
      portal: 'Documents',
      training: 'Formation en cours',
      documentPdf: 'Feuille émargement',
      daytime: 'Mes journées',
      404: '404'
    }
  },
  menu: {
    title: 'Menu',
    calendar: 'Calendrier',
    journeys: 'Mes journées',
    survey: 'Questionnaires',
    portal: 'Documents',
    account: 'Mon compte',
    home: 'Accueil'
  },
  login: {
    googleSSO: "S'identifier avec Google",
    microsoftLogin: "S'identifier avec Microsoft",
    microsoftRedirect: 'Peut prendre quelques minutes',
    redirectionProgress: 'Redirection en cours',
    identification: 'Reconnexion automatique',
    autologin: 'Une session est déjà connectée. Voulez-vous y accéder ?',
    title: "S'identifier",
    portal: {
      partner: {
        access: 'Accès commanditaire',
        button: "Accédez à l'espace commanditaire"
      },
      trainer: {
        access: 'Accès animateur',
        button: "Accédez à l'espace animateur"
      }
    },
    sponsor: {
      infoPassword:
        'Le mot de passe est celui de votre compte commanditaire, identique pour toutes vos organisations'
    },
    label: {
      stayConnected: 'Rester connecté',
      connect: 'Se connecter',
      needHelp: "Besoin d'aide ?",
      incorrectCredential: 'Identifiant incorrect',
      wrongCode: 'Code incorrect',
      cantResendCode: 'Impossible de renvoyer un code'
    },
    email: {
      link: 'Connexion par code pin',
      placeholder: 'Email'
    },
    password: {
      link: 'Connexion par mot de passe',
      placeholder: 'Mot de passe'
    },
    entreprise: {
      title: 'Se connecter en mode entreprise'
    },
    code: {
      email: "Un code d'accès vous a été envoyé par mail",
      sms: "Un code d'accès vous a été envoyé par sms",
      resend: 'Renvoyer un code de vérification',
      error: "Le code pin n'est pas généré, impossible de le vérifier."
    },
    help: {
      p1FirstPart:
        "Si vous êtes ici, c'est que vous avez reçu une mail vous invitant à utiliser",
      p1SecondPart:
        'pour émarger vos stagiaires. Votre compte est déjà configuré, vous devez juste vous connecter.',
      p2:
        "L'adresse mail sur laquelle vous avez reçu cette invitation vous servira de moyen de connection, veuillez renseigner le champ mail avec cette dernière.",
      p3:
        "Une fois le champ validé, un mail vous sera envoyé à cette même adresse. Cliquez dessus pour générer un code d'accès sécurisé que vous devez recopier dans l'application (pas besoin de retenir ce code, il ne vous servira qu'un fois).",
      p4:
        "Vous pourrez alors accéder à toutes vos formations en un clin d'oeil !"
    },
    organization: {
      title: 'Sous quelle organisation souhaitez-vous vous connecter ?',
      mode: 'Quel mode de connexion souhaitez-vous utiliser ?',
      email: 'EMAIL',
      sms: 'SMS',
      sso: 'SSO'
    },
    error: {
      invalidCode: 'code invalide',
      notValidated: "Vous n'avez pas validé votre compte.",
      noMatchingEmail: 'Aucun compte ne correspond à cette adresse mail',
      noOrganizationPassword:
        "Vos organisations ou vos comptes n'ont pas activé la méthode de connexion par 'mot de passe'",
      noOrganizationGoogle:
        "Vos organisations ou vos comptes n'ont pas activé la méthode de connexion par SSO Google",
      noOrganizationMicrosoft:
        "Vos organisations ou vos comptes n'ont pas activé la méthode de connexion par SSO Microsoft",
      selectAnotherMicrosoftAccount:
        'Veuillez sélectionner un autre compte microsoft',
      titlePopupMicrosoft: 'En cours de connexion microsoft',
      messagePopupMicrosoft:
        'Une fenêtre de connexion microsoft est déjà ouverte, veuillez continuer la connexion sur la fenêtre microsoft ou fermer la fenêtre microsoft avant toute autre action',
      pinRequestRequired:
        "Il faut d'abord faire une demande de code PIN, aucune trouvée.",
      waitingDelay:
        "Un délai est nécessaire avant l'envoie d'une nouvelle requête.",
      notGenerated:
        "Le code pin n'est pas généré, veuillez cliquer sur le lien envoyé par mail/SMS.",
      expired:
        'Le code PIN a expiré, merci de recommencer le processus de connexion.',
      pinAlreadyGenerated:
        "Cette requète ne peut se faire qu'avant la génération du code or celui-ci est déjà généré.",
      notValid:
        "Le code n'est pas valide, le compteur de tentatives a été incrémenté.",
      invalidCredentials: 'Email ou mot de passe incorrect.',
      invalidPassword: 'Mot de passe incorrect',
      methodNotAllowed:
        "La connexion n'est pas autorisée pour cette organisation, l'utilisateur n'appartient pas à l'organisation, ou l'utilisateur n'a pas de mot de passe configuré",
      invalidAccess: "Le code d'accès fourni est invalide",
      passwordDisabled: "Le mot de passe n'est pas activé pour ce compte.",
      ssoError:
        "Une erreur est survenue pendant la connexion SSO. Si l'erreur persiste, contactez votre organisation",
      failDisconnectSSO: 'La déconnexion SSO à échouée',
      expiredToken: 'Votre jeton de connexion a expiré. Veuillez réessayer.',
      trainerNotFound: 'Aucun animateur ne correspond à cette adresse mail',
      invalidToken: "Votre jeton de connexion n'est pas valide.",
      error: 'Erreur inconnue'
    }
  },
  errors: {
    unknown: 'Erreur inconnue',
    needModeration:
      'La modification est soumise à une validation administrative'
  },
  lock: {},
  home: {
    label: {
      yesterday: 'Hier',
      tomorrow: 'Demain',
      previously: 'Précédemment',
      commingSoon: 'Prochainement',
      today: "Aujourd'hui"
    }
  },
  calendar: {
    days: {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesdeay: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche'
    },
    renderRange: 'Semaine du {weekStart} au {weekEnd}'
  },
  account: {
    title: 'Mon compte',
    logout: {
      title: 'Se déconnecter',
      message: 'Etes-vous sûr de vouloir vous déconnecter ?',
      action: 'Déconnexion'
    },
    logoutSSORequest: {
      title: 'Déconnexion du SSO',
      message: 'Voulez-vous également vous déconnecter du SSO?',
      action1: 'Se déconnecter du SSO',
      action2: "Se déconnecter seulement de l'application"
    },
    toast: {
      logout: 'Au revoir',
      logoutSingleOrganization: "Déconnecté de  l'organisation",
      loginSingleOrganization: "Connecté à l'organisation"
    },
    information: {
      title: 'Informations personnelles'
    },
    help: {
      title: "Besoin d'aide ?",
      button: 'Aide en ligne',
      organisationTitle: 'Pour contacter votre organisation :'
    },
    organizationList: {
      title: 'Mes organisations connectées',
      labelButton: 'Gérer les organisations / Se déconnecter',
      modal: {
        appLogoutText:
          "Pour quitter l'application, veuillez vous déconnecter de toutes les organisations",
        closeWindow: 'Fermer la fenêtre',
        connectedAs: 'Vous êtes connecté en tant que',
        description: 'Liste des organisations disponibles',
        loginAction: 'Se connecter',
        loginInfo: 'Connecté',
        logoutAction: 'Se déconnecter',
        logoutInfo: 'Déconnecté',
        logoutTitle: 'organisations liées à'
      }
    },
    trainerOrganizationList: {
      title: 'Mes organisations connectées',
      labelButton: 'Gérer les organisations',
      modal: {
        appLogoutText:
          "Pour quitter l'application, veuillez vous déconnecter de toutes les organisations",
        back: 'Revenir sur la liste des organisations',
        closeWindow: 'Fermer la fenêtre',
        connectedAs: 'Vous êtes connecté en tant que',
        description: 'Liste des organisations disponibles',
        loginAction: 'Se connecter',
        loginInfo: 'Connecté',
        logoutAction: 'Se déconnecter',
        logoutInfo: 'Déconnecté',
        logoutTitle: 'organisations liées à',
        switchAction: 'Accéder'
      }
    },
    security: {
      title: 'Paramètre de sécurité',
      manage: 'Gestion du mot de passe',
      accessOur: 'Accèdez à notre',
      privacyPolicy: 'Politique de confidentialité',
      password: {
        add: {
          title: "Gestion de l'écran de verrouillage"
        },
        delete: {
          title: 'Supprimer mon mot de passe',
          warning:
            'Vous allez désactiver votre mot de passe. Nous allons vous envoyer un email de confirmation avant de valider le changement.',
          success:
            'La connexion par mot de passe a bien été supprimée de votre compte.'
        },
        edit: {
          title: 'Modifier mon mot de passe'
        },
        mixin: {
          success: 'Votre nouveau mot de passe a été pris en compte.'
        },
        input: {
          help:
            'Vous devez choisir un mot de passe respectant les critères sur la droite. Veuillez éviter un mot de passe trop simple afin de protéger votre compte des connexions non autorisées.',
          helpTitle: 'A respecter',
          helpLength: '15 caractères minimum',
          helpUppercase: 'Au moins 1 caractère en majuscule',
          helpLowercase: 'Au moins 1 caractère en minuscule',
          helpNumber: 'Au moins 1 chiffre',
          helpMatching: 'Les mots de passes doivent être identique',
          errorLength: 'Le mot de passe fait moins de 15 caractères.',
          errorMatch: 'Les mots de passe ne correspondent pas.'
        },
        code: {
          label: 'Code de validation',
          empty: 'Le code est vide.',
          resend: 'RENVOYER LE CODE DE VÉRIFICATION',
          resendTitleDisable:
            'Attendez la fin du timer pour redemander un code',
          resendTitle:
            'Cliquez afin de renvoyer un code de vérification par mail',
          help:
            'Veuillez renseigner le code de confirmation qui vous a été envoyé par mail pour valider votre demande.'
        },
        manage: {
          title: 'Gestion du mot de passe',
          helpHasPassword:
            'Ici vous pouvez modifier votre mot de passe de connexion ou le désactiver pour votre compte.',
          helpHasNotPassword:
            "Vous n'avez pas configuré de mot de passe, vous pouvez l'activer afin de vous connecter plus simplement dans le futur mais cela diminuera la sécurité de votre compte.",
          add: 'Activer mon mot de passe',
          delete: 'Désactiver mon mot de passe',
          edit: 'Modifier mon mot de passe'
        }
      }
    },
    add: {
      title: 'Ajout de partie'
    },
    preference: {
      title: 'Préférences animateur',
      smsAllow: {
        title: 'Recevoir les rappels par SMS',
        helpSingular:
          'Vous recevrez un SMS {nbDay} jour avant une formation pour vous rappeler de télécharger les données.',
        helpPlurial:
          'Vous recevrez un SMS {nbDay} jours avant une formation pour vous rappeler de télécharger les données.'
      },
      managePdf: 'Télécharger les feuilles de présence',
      emargeDoc: "Feuille d'émargement",
      emargeDocEmpty: "Aucune feuille de présence n'existe actuellement"
    }
  },
  journey: {
    title: 'Mes journées',
    empty: 'Aucune session programmée.',
    label: {
      training: 'Formation',
      session: 'Session',
      section: 'Partie',
      day: 'Jour'
    },
    selectDate: {
      all: 'Tous',
      today: "Aujourd'hui",
      yesterday: 'Hier',
      tomorrow: 'Demain'
    }
  },
  day: {
    gaps: {
      info: 'Retard en minute',
      latenessLabel: 'Retard :'
    },
    departureEarliness: {
      info: 'départ anticipé en minute',
      latenessLabel: 'Départ anticipé :'
    },
    toast: {
      endMorning: 'Matinée terminée',
      end: 'Clôturé',
      replace: 'Remplacé',
      add: 'Ajouté',
      edit: 'Modifié',
      delete: 'Supprimé',
      signe: 'Signé',
      absence: 'Absence confirmée',
      addGaps: 'Retard ajouté',
      departureEarliness: 'Départ anticipé ajouté',
      send: 'Envoyé',
      needConfirm:
        "Les modifications ne seront appliquées qu'après vérification auprès du responsable de la formation",
      copy: 'Copié',
      copyError: 'Erreur durant la copie',
      confirmDeleteAttendance: 'Êtes-vous sûr de supprimer cette inscription ?',
      confirmDeleteSignature: 'Êtes-vous sûr de supprimer cette signature ?'
    },
    label: {
      signedAt: 'Signé à',
      emailSend: 'Mail envoyé',
      failedEmailSend: 'Échec envoi mail',
      personSingular: 'personne',
      personPurial: 'personnes',
      trainee: 'stagiaire',
      trainees: 'stagiaires',
      trainer: 'animateur',
      trainers: 'animateurs',
      remainingsSignature: 'signatures restantes',
      remainingSignature: 'signature restante',
      search: 'Rechercher',
      information: 'Informations',
      reference: 'Référence',
      organization: 'Organisme',
      dates: 'Dates',
      duration: 'Durée',
      location: 'Lieu',
      at: 'au',
      hours: 'heures',
      minutes: 'minutes',
      showDetails: 'Afficher les détails',
      hideDetails: 'Cacher les détails',
      noDetail: 'Aucune signature',
      synchronisation: 'Synchronisation'
    },
    part: {
      morning: 'Matin',
      afternoon: 'Après-midi',
      all: 'Journée'
    },
    attendee: {
      empty: 'Liste vide'
    },
    tooltip: {
      delAttendance: "Supprimer l'inscription du stagiaire",
      delAttendanceSignature: 'Supprimer la signature',
      sign: 'Signer la présence',
      reSign: 'Recommencer et voir la signature',
      departureEarliness: 'Ajouter un départ anticipé',
      empty: 'Aucune action possible',
      emergeApp: "Émargement sur l'application",
      emergeEmail: 'Émargement via mail',
      emergeDynamicQRCode: 'Émargement via QR Code dynamique',
      gapsTrainee: 'Ajouter un retard',
      backEmerge: "Retourner à l'émargement",
      replace: 'Remplacer ce stagiaire',
      addTrainee: 'Ajouter un stagiaire',
      addTraineeFromBack: 'Importer un stagiaire existant',
      addNewTrainee: 'Créer un nouveau stagiaire',
      edit: 'Modifier ce stagiaire',
      manageSurvey: 'Gestion des questionnaires',
      displaySectionReport: "Afficher la feuille d'émargement",
      docs: "Afficher les feuilles d'émargements",
      absent: 'Passer en absent',
      comment: 'Ajouter un commentaire',
      commentModalTitle: 'Commentaires',
      collectiveSignature: 'Signature collective',
      makeCall: "Faire l'appel",
      disableEmerge: "Il n'y a aucun stagiaire à émarger"
    },
    email: {
      report: 'Erreurs pour les participants:',
      errors: "Les mails suivant n'ont pas pu être envoyés : {emailList}",
      title: 'Sélectionnez les présents',
      selectAll: 'Sélectionner tout',
      noMail: "Ce stagiaire n'a pas de mail"
    },
    emerge: {
      quit: "Êtes-vous sûr de vouloir quitter l'émargement ?",
      confirmAbsent: "Confirmer l'absence",
      replace: 'Remplacer',
      edit: 'Modifier',
      addTrainee: 'Ajouter un stagiaire'
    },
    error: {
      missingPart: "La partie a été annulée ou n'existe plus.",
      accessDenied: "Vous n'avez pas accès à cette partie.",
      tryReconnect:
        'Si le problème persiste, vous pouvez tenter de vous reconnecter.'
    },
    button: {
      end: 'Terminer',
      start: 'Démarrer',
      finish: 'Clôturer'
    },
    replace: {
      title: 'Remplacé {firstName} {lastName} par :'
    },
    add: {
      title: 'Ajouter un stagiaire'
    },
    edit: {
      title: 'Modifier {firstName} {lastName}:'
    },
    comment: {
      about: 'Votre commentaire concernant {firstName} {lastName}:',
      info:
        'Votre commentaire sera visible par votre organisation. Utilisez-le pour transmettre des informations complémentaires.',
      emptyComment: 'Veuillez saisir un commentaire'
    },
    template: {
      emptyLastName: 'Le nom est vide',
      emptyFirstName: 'Le prénom est vide',
      formatPhone: "Le numéro n'est pas valide"
    },
    survey: {
      title: 'Mes questionnaires',
      home: 'Action Évaluation/Questionnaire',
      error: 'Des erreurs sont survenues pour ces stagiaires',
      choice_send: 'Sélectionner le questionnaire à envoyer',
      choice_eval_send: "Sélectionner l'évaluation",
      choice: 'Sélectionner le questionnaire',
      trainee: 'Sélectionner le stagiaire',
      resEmpty: 'Aucun résultat disponible',
      empty: 'Aucun questionnaire disponible',
      response: 'Voir les réponses',
      surveySend: 'Envoyer un questionnaire',
      evalSend: 'Évaluer un stagiaire',
      individual: 'Sélectionner les participants au questionnaire',
      collective: 'Lien collectif',
      mode: 'Sélectionner le mode d’envoi',
      invalidTraineeEmail: "Ce stagiaire n'a pas de mail valide",
      labelIndividual: 'Partager par mail - Lien individuel',
      labelCollective: 'Partager par QRCode - Lien collectif',
      openConfirmNewWindow:
        'Voulez-vous ouvrir le questionnaire dans un nouvel onglet ?'
    },
    report: {
      title: 'Rapport de session',
      detail: 'Afficher le détail de session',
      detailTitle: 'Récapitulatif des signatures',
      detailSign: 'Recommencer la signature',
      noAbsence: 'Aucun absent',
      absentSingular: 'absent',
      absentPlural: 'absents',
      errorAbsence:
        "Le nombre d'absent est anormalement élevé, merci de commenter le rapport de session pour expliquer cette situation",
      addComment: 'Ajouter un commentaire',
      editComment: 'Editer :',
      certificate:
        "En envoyant, je certifie que les données d'émargement sont correctes"
    },
    collectiveSign: {
      createTeamsChannel: "Ajouter l'onglet de signature dans Teams",
      title: 'Signature collective',
      subtitle: 'Sélectionner les stagiaires présents',
      subtitleHelp:
        'Les autres seront passés absents et ne pourront pas signer.',
      linkTitle: 'Lien collectif',
      confidential: 'Mode confidentiel',
      codeRegistration: "Code d'inscription",
      help:
        'Avec le mode confidentiel, les stagiaires doivent entrer leur nom complet, leur email ou le code inscription avant de signer.',
      error: "Erreur lors de l'envoie du lien collectif"
    },
    DynamicQRCode: {
      title: 'QR Code dynamique',
      textInfo:
        'Vos Participants doivent scanner le QR code depuis leur accès Bienvenue Formation'
    }
  },
  newSection: {
    AddTrainingModalTitle: 'Ajouter une formation',
    AddSessionModalTitle: 'Ajouter une session',
    AddSectionModalTitle: 'Ajouter une partie',
    AddTraineesModalTitle: 'Ajouter des stagiaires',
    title: 'Ajouter une partie',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    goToSection: 'Accès à la nouvelle partie',
    search: 'Rechercher ',
    objectTitle: 'Titre',
    objectDay: 'Jour de formation',
    objectStartAt: 'Date de début',
    objectEndAt: 'Date de fin',
    objectStartHour: 'Heure de début',
    objectEndHour: 'Heure de fin',
    objectCode: 'Code',
    objectDureHour: 'Durée Heures',
    objectDureMin: 'Durée Minutes',
    objectLocation: 'Lieu',
    objectAddress: 'Adresse',
    objectZipcode: 'Code Postal',
    objectCity: 'Ville',
    objectCountry: 'Pays',
    emptyListTraining: 'Aucune formation',
    emptyListSession: 'Aucune session',
    selectTraining: 'Sélectionner une formation',
    selectSession: 'Sélectionner une session',
    selectSection: 'Partie',
    addSection: 'Ajouter une partie',
    addTrainees: 'Ajouter des stagiaires',
    nbPage: 'Page {page} sur {pageMax}',
    traineeAssignedText: 'Stagiaires sélectionnés : ',
    goCurrentSection: 'Rejoindre la partie',
    currentTraining: 'Formation actuelle : {trainingName}',
    currentSession: 'Session actuelle : {sessionName}',
    error: {
      sessionDate: 'Les dates de début et de fin ne sont pas cohérentes',
      sessionSameDate: 'Les dates début et de  de fin sont les mêmes'
    },
    NotRequired: 'Laissez vide si inconnu'
  },
  todo: {
    todoSoon: 'Bientôt disponible'
  },
  loginTeams: {
    title: 'Connexion en cours...',
    error: 'Oups la connexion à échouée. Réessayer ou contacter le support'
  },
  trainerSurveyEntries: {
    actifs: 'Actifs',
    expired: 'Terminés/Expirés',
    hasExpire: 'A expiré le : ',
    expireTo: 'Expire le : ',
    completed: 'Complété'
  },
  portal: {
    title: 'Mes documents',
    emptyDocumentList: 'Aucun document ne correspond à votre recherche',
    filter: {
      search: 'Rechercher le nom du document',
      panelTitle: 'Filtrer par',
      confirmButton: 'Appliquer les filtres',
      resetFilter: 'Effacer les filtres',
      emptyFilterList: 'Aucune correspondance',
      titleDisplayFilters: 'Afficher les filtres',
      titleHideFilters: 'Cacher les filtres',
      button: {
        category: 'Catégorie',
        selectedPeriod: 'Période',
        formation: 'Formation',
        session: 'Session',
        part: 'Partie',
        trainer: 'Animateur',
        trainee: 'Stagiaire',
        survey: 'Questionnaire',
        more: 'Plus de filtres',
        less: 'Moins de filtres'
      },
      category: {
        free: 'Libre',
        groupCompactSheet: 'Feuille de présence périodique collective',
        PackAttendanceSheet: 'Groupe de feuilles de présence individuelles',
        PackCompactSheet:
          'Groupe de feuilles de présence périodiques individuelles',
        PackOfAchievementCertificate:
          "Groupe d'attestations ou de certificats de réalisation",
        SignedDocument: 'Document signé',
        PackSignedDocument: 'Groupe de documents signés',
        SurveyConsolidationSheet: 'Synthèse des résultats questionnaire',
        PackSurveyPerAttendeePdf: 'Groupe de résultats questionnaire PDF',
        SurveyCsvResults: 'Résultats CSV questionnaire'
      }
    },
    list: {
      header: {
        documentName: 'Nom du document',
        category: 'Catégorie',
        fileType: 'Type de fichier',
        documentPartner: 'Organisation'
      }
    }
  },
  updateSection: {
    title: 'Modifier la partie',
    planningTitle: 'Planning',
    sectionName: 'Nom de la partie',
    sectionCode: 'Code',
    sectionType: 'Type',
    sectionStartDate: 'Date de début',
    sectionEndDate: 'Date de fin',
    sectionStartHour: 'Heure de début',
    sectionEndHour: 'Heure de fin',
    sectionMorning: {
      startHour: 'Heure du début de matinée',
      endHour: 'Heure de fin matin de matinée'
    },
    sectionAfternoon: {
      startHour: "Heure de début d'après-midi",
      endHour: "Heure de fin d'après-midi"
    },
    sectionTimezone: 'Fuseau horaire',
    sectionAutoCreateSequences:
      'Découper automatiquement la partie en demi-journées',
    sectionLocation: 'Lieu',
    sectionAddress: 'Adresse',
    sectionZipcode: 'Code postal',
    sectionCity: 'Ville',
    sectionCountry: 'Pays',
    sectionMode: 'Mode',
    sectionSignBeforeSlotStarDelay:
      "Autoriser le début de l'émargement en autonomie avant le début du créneau (en minutes)",
    sectionSite: 'Site',
    mode: {
      on_site: 'Présentiel',
      remote: 'A distance',
      hybrid: 'Hybride'
    },
    sectionTags: 'Tags',
    addTag: 'Chercher un tag',
    addSite: 'Chercher un site',
    emptyTag: "Aucun tag n'est attribué à la partie",
    emptySite: "Aucun site n'est attribué à la partie"
  },

  updateRegistration: {
    title: "Modifier l'inscription",
    reference: "Numéro d'inscription",
    financingMethod: 'Méthode de financement',
    mealIncluded: 'Repas inclus',
    customFieldsTitle: 'Champs additionnels'
  }
}
